<template>
  <v-container>
    <v-row>
      <v-col>
        <v-breadcrumbs
          :items="breadcrumbs"
          class="pa-0"
          large
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-card>
          <v-card-title>
            Nieuwe gebruiker
          </v-card-title>

          <v-card-text v-if="allLoaded">
            <v-row>
              <v-col>
                <v-text-field
                  v-model="newUser.firstName.value"
                  :error-messages="newUser.firstName.errors"
                  autofocus
                  label="Voornaam"
                  @blur="validateFirstName"
                  @input="newUser.firstName.errors = []"
                />
              </v-col>

              <v-col>
                <v-text-field
                  v-model="newUser.lastName.value"
                  :error-messages="newUser.lastName.errors"
                  label="Achternaam"
                  @blur="validateLastName"
                  @input="newUser.lastName.errors = []"
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-text-field
                  v-model="newUser.email.value"
                  :error-messages="newUser.email.errors"
                  label="E-mail"
                  @blur="validateEmail"
                  @input="newUser.email.errors = []"
                />
              </v-col>

              <v-col>
                <v-select
                  v-model="newUser.type.value"
                  :error-messages="newUser.type.errors"
                  :items="types"
                  label="Functie"
                  clearable
                  @input="validateType"
                />
              </v-col>
            </v-row>

            <v-row v-if="sortedOrganizations && sortedOrganizations.length !== 1">
              <v-col
                cols="12"
                sm="6"
              >
                <v-select
                  v-model="newUser.organizationId.value"
                  :error-messages="newUser.organizationId.errors"
                  :items="sortedOrganizations"
                  label="Organisatie"
                  clearable
                  @input="validateOrganization"
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                  Kies hieronder de functie die je de gebruiker wilt geven:

                  <ul>
                      <li>
                      Mag de gebruiker alles zien en aanpassen alleen op
                      specifieke locatie(s), selecteer 'Is admin' en selecteer
                      de locatie(s). (Deze gebruiker ziet ook de Wolk
                      heupairbags zonder locatie en kan deze aan zijn/haar eigen
                      locatie toevoegen)
                      </li>
                      <li>
                      Mag de gebruiker alles zien en aanpassen van de hele
                      organisatie kies dan 'Is admin' en 'ziet alle locaties'.
                      </li>
                      <li>
                      Mag de gebruiker niks aanpassen maar alleen inzien, kies
                      dan 'Is view-only' en selecteer de locatie(s).
                      </li>
                  </ul>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-switch
                  v-model="newUser.isAdmin.value"
                  :disabled="newUser.isViewOnly.value"
                  label="Is admin (kan ook gebruikers/locaties toevoegen)"
                />
                <v-switch
                  v-model="newUser.isViewOnly.value"
                  :disabled="newUser.isAdmin.value"
                  label="Is view-only (kan niks aanpassen)"
                />
                <v-switch
                  v-if="accessToAllLocations"
                  v-model="allLocationsSelected"
                  label="Ziet alle locaties"
                />
              </v-col>
            </v-row>

            <v-row v-if="newUser.organizationId.value">
              <v-col
                v-if="newUser.locationIds.errors.length > 0"
                cols="12"
              >
                <v-alert
                  border="bottom"
                  colored-border
                  type="warning"
                  elevation="2"
                  class="mb-0"
                >
                  {{ newUser.locationIds.errors[0] }}
                </v-alert>
              </v-col>

              <v-col cols="12">
                <wolk-user-locations-selector
                  v-if="!allLocationsSelected"
                  ref="locationsSelector"
                  :organization-id="newUser.organizationId.value"
                  :selected-user-location-ids.sync="newUser.locationIds.value"
                  :user-locations.sync="userLocations"
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-btn
                  color="primary"
                  :loading="loading"
                  @click="validateAndCreate"
                >
                  Maak aan
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from 'axios';

import WolkUserLocationsSelector from './UserLocationsSelector.vue';

export default {
  components: {
    WolkUserLocationsSelector,
  },
  data() {
    return {
      loading: false,
      newUser: {
        allLocations: {
          value: '',
          errors: [],
        },
        firstName: {
          value: '',
          errors: [],
        },
        lastName: {
          value: '',
          errors: [],
        },
        email: {
          value: '',
          errors: [],
        },
        isAdmin: {
          value: false,
          errors: [],
        },
        isViewOnly: {
          value: false,
          errors: [],
        },
        organizationId: {
          value: null,
          errors: [],
        },
        locationIds: {
          value: [],
          errors: [],
        },
        type: {
          value: null,
          errors: [],
        },
        createSetPasswordRequest: {
          value: true,
        },
      },
      organizations: {
        loaded: false,
        value: [],
      },
      breadcrumbs: [
        {
          text: 'Wolk dashboard',
          disabled: false,
          exact: true,
          to: { name: 'home' },
        },
        {
          text: 'Gebruikers',
          disabled: false,
          exact: true,
          to: { name: 'users' },
        },
        {
          text: 'Nieuwe gebruiker',
          disabled: true,
          exact: true,
          to: { name: 'users' },
        },
      ],
      allLocationsSelected: false,
      userLocations: [],
    };
  },
  computed: {
    accessToAllLocations() {
      // wolk-admin has access to all locations for all organizations
      if (this.$store.getters['auth/isAdmin']) {
        return true;
      }

      // user has no locations assigned => user has organization access
      if (Array.isArray(this.userLocations) && this.userLocations.length === 0) {
        return true;
      }
      return false;
    },
    allLoaded() {
      return !this.loading && this.organizations.loaded;
    },
    types() {
      return this
        .$store
        .state
        .app
        .userTypes
        .map((type) => ({ text: type.title, value: type.key }));
    },
    sortedOrganizations() {
      return [...this.organizations.value].sort((first, second) => {
        if (first.lowerCasedText > second.lowerCasedText) {
          return 1;
        }

        if (first.lowerCasedText < second.lowerCasedText) {
          return -1;
        }

        return 0;
      });
    },
  },
  watch: {
    // eslint-disable-next-line object-shorthand
    'newUser.organizationId.value'(val) {
      if (val) {
        this.newUser.locationIds.value = [];
      }
    },
    // eslint-disable-next-line object-shorthand
    'newUser.locationIds.value'() {
      this.newUser.locationIds.errors = [];
    },
  },
  beforeMount() {
    this.getLoggedInUser();
    this.loadOrganizations();
  },
  methods: {
    loadOrganizations() {
      const url = `${config.VUE_APP_API_BASE_URL}/organizations`;

      axios({
        url,
        headers: {
          Authorization: `Bearer ${this.$store.state.auth.jwt}`,
        },
        method: 'get',
      })
        .then((response) => {
          this.organizations.value = response
            .data
            .organizations
            .map((loadedOrganization) => ({
              text: loadedOrganization.title,
              lowerCasedText: loadedOrganization.title.toLowerCase(),
              value: loadedOrganization._id,
            }));

          if (this.organizations.value.length === 1) {
            this.newUser.organizationId.value = this.organizations.value[0].value;
          }

          this.organizations.loaded = true;
        });
    },
    createUser() {
      const url = `${config.VUE_APP_API_BASE_URL}/users`;

      this.loading = true;

      const data = {
        firstName: this.newUser.firstName.value,
        lastName: this.newUser.lastName.value,
        email: this.newUser.email.value,
        type: this.newUser.type.value,
        organizationId: this.newUser.organizationId.value,
        isAdmin: this.newUser.isAdmin.value,
        isViewOnly: this.newUser.isViewOnly.value,
        createSetPasswordRequest: this.newUser.createSetPasswordRequest.value,
      };

      data.locationIds = this.newUser.locationIds.value;

      axios({
        method: 'post',
        url,
        headers: {
          Authorization: `Bearer ${this.$store.state.auth.jwt}`,
        },
        data,
      })
        .then(() => {
          this.$store.commit('app/snackbarText', {
            text: 'Er is een mail naar de nieuwe gebruiker verzonden met inlog instructies',
            visible: true,
          });

          this.$router.push({ name: 'users' });
        })
        .catch((error) => {
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            console.log(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error', error.message);
          }

          console.log(error.config);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    validateFirstName() {
      this.newUser.firstName.value = this.newUser.firstName.value.trim();

      const newErrors = [];
      if (!this.newUser.firstName.value) {
        newErrors.push('Is verplicht');
      }

      if (this.newUser.firstName.value.length > 17) {
        newErrors.push('Mag maximaal 17 karakters lang zijn');
      }

      this.newUser.firstName.errors = newErrors;
    },
    validateLastName() {
      this.newUser.lastName.value = this.newUser.lastName.value.trim();

      const newErrors = [];
      if (!this.newUser.lastName.value) {
        newErrors.push('Is verplicht');
      }

      if (this.newUser.lastName.value.length > 17) {
        newErrors.push('Mag maximaal 17 karakters lang zijn');
      }

      this.newUser.lastName.errors = newErrors;
    },
    validateEmail() {
      this.newUser.email.value = this.newUser.email.value.trim();

      const newErrors = [];
      if (!this.newUser.email.value) {
        newErrors.push('Is verplicht');
      }
      const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!pattern.test(this.newUser.email.value)) {
        newErrors.push('Is not a valid email');
      }

      this.newUser.email.errors = newErrors;
    },
    validateType() {
      const newErrors = [];
      if (!this.newUser.type.value) {
        newErrors.push('Is verplicht');
      }

      this.newUser.type.errors = newErrors;
    },
    validateOrganization() {
      const newErrors = [];
      if (!this.newUser.organizationId.value) {
        newErrors.push('Is verplicht');
      }

      this.newUser.organizationId.errors = newErrors;
    },
    validateLocations() {
      const newErrors = [];

      if (this.allLocationsSelected) {
        this.newUser.locationIds.value = [];
      }

      if (!this.allLocationsSelected && this.newUser.locationIds.value.length === 0) {
        newErrors.push('Er dient mininaal 1 locatie toegevoegd te zijn.');
      }

      this.newUser.locationIds.errors = newErrors;
    },
    validateAndCreate() {
      this.validateFirstName();
      this.validateLastName();
      this.validateEmail();
      this.validateOrganization();
      this.validateLocations();
      this.validateType();

      if (this.newUser.firstName.errors.length > 0) return;
      if (this.newUser.lastName.errors.length > 0) return;
      if (this.newUser.email.errors.length > 0) return;
      if (this.newUser.organizationId.errors.length > 0) return;
      if (this.newUser.locationIds.errors.length > 0) return;
      if (this.newUser.type.errors.length > 0) return;

      this.createUser();
    },
    getLoggedInUser() {
      if (this.$store.getters['auth/isAdmin']) {
        return;
      }
      const url = `${config.VUE_APP_API_BASE_URL}/users/${this.$store.state.auth.id}`;
      axios({
        url,
        headers: {
          Authorization: `Bearer ${this.$store.state.auth.jwt}`,
        },
        method: 'get',
      }).then((response) => {
        const { user } = response.data;
        if (user) {
          this.userLocations = user.metaData?.locationIds || [];
        }
      });
    },
  },
};
</script>
