<template>
  <v-alert
    v-if="!loading && loadedLocations.length === 0"
    border="bottom"
    colored-border
    type="warning"
    elevation="2"
    class="mb-0"
  >
    Geen locaties gevonden voor deze organisatie
  </v-alert>

  <v-card v-else-if="loading">
    <v-card-text>
      <v-progress-linear
        indeterminate
        color="primary"
        class="my-3"
      />
    </v-card-text>
  </v-card>

  <v-card v-else>
    <v-card-title>
      Selecteer de locaties voor deze gebruiker
    </v-card-title>

    <v-list dense>
      <v-list-item
        v-for="loadedLocation in loadedLocations"
        :key="loadedLocation._id"
        @click="toggleLocation(loadedLocation._id)"
      >
        <v-list-item-action>
          <v-icon :color="locationChecked(loadedLocation._id) ? 'success' : 'grey'">
            {{
              locationChecked(loadedLocation._id)
                ? $vuetify.icons.values.checkboxOn
                : $vuetify.icons.values.checkboxOff
            }}
          </v-icon>
        </v-list-item-action>

        <v-list-item-content>
          {{ loadedLocation.title }}
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-card>
</template>

<script>
import axios from 'axios';

export default {
  name: 'WolkUserLocationsSelector',
  props: {
    organizationId: {
      type: String,
      required: true,
    },
    selectedUserLocationIds: {
      type: Array,
      default: () => [],
    },
    userLocations: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      loading: false,
      loadedLocations: [],
      loadLocationsCancelToken: null,
    };
  },
  watch: {
    organizationId(val) {
      console.log({ newOrganizationId: val });

      if (val) {
        this.loadLocations();
      }
    },
  },
  beforeMount() {
    this.loadLocations();
  },
  methods: {
    locationChecked(locationId) {
      return this.selectedUserLocationIds.includes(locationId);
    },
    toggleLocation(locationId) {
      if (this.locationChecked(locationId)) {
        // remove selected ID
        this.$emit(
          'update:selectedUserLocationIds',
          this.selectedUserLocationIds.filter((id) => id !== locationId),
        );

        return;
      }

      // add selected ID
      this.$emit(
        'update:selectedUserLocationIds',
        [...this.selectedUserLocationIds, locationId],
      );
    },
    loadLocations() {
      if (this.loadLocationsCancelToken) {
        console.log('Calling cancel token');
        this.loadLocationsCancelToken();
      }

      const url = `${config.VUE_APP_API_BASE_URL}/locations`;

      const params = {};
      if (this.$store.getters['auth/isAdmin']) {
        params.organizationId = this.organizationId;
      }

      this.loading = true;

      axios({
        method: 'get',
        url,
        params,
        headers: {
          Authorization: `Bearer ${this.$store.state.auth.jwt}`,
        },
        cancelToken: new axios.CancelToken((c) => {
          this.loadLocationsCancelToken = c;
        }),
      })
        .then((response) => {
          if (response && response.data) {
            this.loadedLocations = this.$store.getters['auth/isAdmin']
              ? response.data.locations
              : response.data.locations
                .filter((l) => {
                  if (this.userLocations.length > 0) {
                    return this.userLocations.includes(l._id);
                  }
                  return true;
                });
          }
        })
        .catch((error) => {
          if (axios.isCancel(error)) {
            console.log('loadLocations is cancelled');
          } else if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            console.log(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error', error.message);
          }

          console.log({ errorConfig: error.config });
        })
        .finally(() => {
          this.loading = false;
          this.loadLocationsCancelToken = null;
        });
    },
  },
};
</script>
